import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dashboard from '../../views/Dashboard'

import NavigationHeader from '../../components/NavigationHeader'
import ExhibitorBar from '../../components/ExhibitorBar'
import ExhibitorBarDetailed from '../../components/ExhibitorBarDetailed'
import ExhibitorDescription from '../../components/ExhibitorDescription'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'

import MainTitle from '../../components/MainTitle'
import MainSettings from '../../components/MainSettings'
import MainSearch from '../../components/MainSearch'
import MainAside from '../../components/MainAside'

import Localization from '../../components/Localization'
import TabTitle from '../../components/TabTitle'
import searchSubstring from '../../helpers/searchSubstring'

import { useSelector, useDispatch } from 'react-redux'
import {
  selectSlug,
  selectExhibitor,
  selectExhibitors,
  selectWidth,
} from '../../redux/dashboard/selectors'
import { asyncFetchExhibitor } from '../../redux/dashboard/async'
import { MapPointsProps } from '../../redux/dashboard/interfaces'
import translate from '../../translate'

const ExhibitorsList: React.FC = () => {
  const [actualExhibitor, setActualExhibitor] = useState<undefined | number>(undefined)
  const [menu, setMenu] = useState(false)
  const exhibitors = useSelector(selectExhibitors)
  const exhibitor = useSelector(selectExhibitor(actualExhibitor))
  const slug = useSelector(selectSlug)
  const width = useSelector(selectWidth)

  const [search, setSearch] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (slug !== undefined && actualExhibitor !== undefined) {
      dispatch(asyncFetchExhibitor({ slug, id: actualExhibitor }))
    }
  }, [slug, actualExhibitor])

  return (
    <Dashboard activePage="exhibitors">
      <div className="main__container">
        {(width >= 1025 || actualExhibitor === undefined) && (
          <div className="main__wrapper">
            <NavigationHeader firstItem={translate('exhibitors')} />
            <div className="main__top">
              <MainTitle title={translate('exhibitors')} />
              <MainSearch value={search} onChange={setSearch} />
            </div>
            {exhibitors && exhibitors.length > 0 && (
              <ExhibitorBar>
                <>
                  {/* <TabTitle title="it solutions" className="exhibitor-bar-detailed-title" /> */}
                  {exhibitors?.map((element: MapPointsProps) => {
                    if (searchSubstring(element.name, search)) {
                      return (
                        <ExhibitorBarDetailed
                          key={element.id}
                          exhibitor={element.name}
                          exhibitorStand={element.stand_number}
                          description={element.short_content}
                          logo={element.logo}
                          id={element.id}
                          onClick={setActualExhibitor}
                        />
                      )
                    }
                  })}
                </>
              </ExhibitorBar>
            )}
          </div>
        )}

        {exhibitors
          ?.filter((element: MapPointsProps) => element.id === actualExhibitor)
          .map((element: MapPointsProps) => {
            return (
              <MainAside key={element.id}>
                <ExhibitorDescription
                  logo={element.logo}
                  name={element.name}
                  text={exhibitor?.content}
                />
                {/* <TabTitle title={translate('localization')} />
                <Link to={`/event/${slug}/map`}>
                  <Localization
                    name={element.name}
                    address={element.stand_number && `Stoisko ${element.stand_number}`}
                  />
                </Link> */}
              </MainAside>
            )
          })}
      </div>

      {actualExhibitor && (
        <div className="sidebar__mobile-bar-wrapper sidebar__mobile-bar-wrapper-agenda">
          <div className="agenda-bar-item">
            <span>Zamknij</span>
            <button
              onClick={() => {
                setActualExhibitor(undefined)
              }}
            >
              <Icon
                name={Icons.upArrow}
                color={IconsColor.xiaomi}
                hoverColor={IconsColor.xiaomi}
                type={IconsFillType.fill}
              />
            </button>
          </div>
        </div>
      )}
    </Dashboard>
  )
}

export default ExhibitorsList
