import React, { FC, useState } from 'react'
import LoginInput from '../../components/LoginInput'
import { useSelector, useDispatch } from 'react-redux'
import { asyncFetchPasswordChange } from '../../redux/user/async'
import { selectUser, selectUserLoading } from '../../redux/user/selectors'
import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { Button } from 'antd'
import translate from '../../translate'

const ProfilePassword: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const loading = useSelector(selectUserLoading)

  const [profilePassword, setProfilePassword] = useState({
    old_password: '',
    new_password1: '',
    new_password2: '',
  })

  const validate = () => {
    if (profilePassword.new_password1 !== profilePassword.new_password2) {
      dashboardMessage(DashboardMessageTypes.error, 'Wprowadzone hasła różnią się od siebie!')
      return false
    }

    return true
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validate()) {
      dispatch(
        asyncFetchPasswordChange({
          additionalHeaders: { Authorization: `JWT ${user?.token}` },
          body: profilePassword,
        }),
      )
      setProfilePassword({
        old_password: '',
        new_password1: '',
        new_password2: '',
      })
    }
  }

  return (
    <div className="login-page">
      <h1>{translate('change_password')}</h1>

      <form onSubmit={handleSubmit}>
        <LoginInput
          label={translate('password')}
          name="old_password"
          type="password"
          value={profilePassword.old_password}
          placeholder={translate('password')}
          onChange={setProfilePassword}
        />

        <div className="login-page-form-row">
          <div className="login-page-form-row__item">
            <LoginInput
              label={translate('new_password')}
              name="new_password1"
              type="password"
              value={profilePassword.new_password1}
              placeholder={translate('new_password')}
              onChange={setProfilePassword}
            />
          </div>
          <div className="login-page-form-row__item">
            <LoginInput
              label={translate('repeat_new_password')}
              name="new_password2"
              type="password"
              value={profilePassword.new_password2}
              onChange={setProfilePassword}
            />
          </div>
        </div>

        <div className="login-page-form-row">
          <div className="login-page-form-row__item profile-login-btn">
            <Button type="primary" loading={loading} htmlType="submit">
              {translate('change_password')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProfilePassword
