import React, { useState, useEffect, FormEvent } from 'react'
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen'
import IntroContainer from '../IntroContainer/IntroContainer'
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar'
import RoomNameScreen from './RoomNameScreen/RoomNameScreen'
import { useAppState } from '../../state'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../js/redux/user/selectors'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

interface PageParams {
  id: string
}

export default function PreJoinScreens() {
  const user = useSelector(selectUser)
  const { getAudioAndVideoTracks } = useVideoContext()
  const { id } = useParams<PageParams>()
  const [step, setStep] = useState(Steps.roomNameStep)

  const [name, setName] = useState<string>(`${user?.firstname} ${user?.lastname}`)
  const [roomName, setRoomName] = useState<string>('')

  const [mediaError, setMediaError] = useState<Error>()

  useEffect(() => {
    if (id) {
      setRoomName(id)
      if (name !== '') {
        setStep(Steps.deviceSelectionStep)
      }
    }
  }, [user, id])

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log('Error acquiring local media:')
        console.dir(error)
        setMediaError(error)
      })
    }
  }, [getAudioAndVideoTracks, step, mediaError])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`#/transmission/${roomName}${window.location.search || ''}`),
      )
    }
    setStep(Steps.deviceSelectionStep)
  }

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  )
}
