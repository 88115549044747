import React, { FC, useEffect, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { asyncFetchEvents, asyncFetchEventContent } from '../../redux/dashboard/async'
import { selectSlug } from '../../redux/dashboard/selectors'
import { selectUser } from '../../redux/user/selectors'
import { setWidth } from '../../redux/dashboard/slice'
import Routes from '../Routes'

const App: FC = () => {
  const dispatch = useDispatch()
  const slug = useSelector(selectSlug)
  const user = useSelector(selectUser)

  useLayoutEffect(() => {
    const splittedHref = window.location.href.split('/')
    const slugIndex = splittedHref.findIndex((element) => element === 'event') + 1
    if (slugIndex > 0) {
      dispatch(asyncFetchEventContent({ slug: splittedHref[slugIndex] }))
    } else if (slug !== undefined) {
      dispatch(asyncFetchEventContent({ slug }))
    }
    dispatch(asyncFetchEvents({ additionalHeaders: {} }))
  }, [slug])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      dispatch(setWidth(newWidth))
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <>
      <Routes />
    </>
  )
}

export default App
