import React, { FC, useState } from 'react'
import Icon from '../../components/Icon'
import { Icons, IconsColor } from '../../utils/enums'
import LoginInput from '../../components/LoginInput'
import { useSelector, useDispatch } from 'react-redux'
import { asyncFetchAccountUpdate, asyncFetchAccountDelete } from '../../redux/user/async'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import translate from '../../translate'

import userImage from '../../../images/user.png'
import AskingModal from '../AskingModal'

const ProfileEdit: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)

  const [profileForm, setProfileForm] = useState({
    photo: '',
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    networking_on: user?.networking_on || 0,
    post: user?.post || '',
    status: user?.status || '',
    company: user?.company || '',
    phone: user?.phone || '',
    email: user?.email || '',
  })

  const [image, setImage] = useState<any>('')

  const photoUpload = (e: any) => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        const profileFormState = { ...profileForm }
        profileFormState.photo = String(reader.result)
        setProfileForm(profileFormState)
        setImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    dispatch(
      asyncFetchAccountUpdate({
        additionalHeaders: { Authorization: `JWT ${user?.token}` },
        body: profileForm,
        token: token || null,
      }),
    )
  }

  const deleteAccount = () => {
    dispatch(
      asyncFetchAccountDelete({
        additionalHeaders: { Authorization: `JWT ${user?.token}` },
      }),
    )
  }

  return (
    <>
      <h1>{translate('edit_profile')}</h1>
      <div className="profile__image">
        <img src={image || user?.photo_url || userImage} alt="user" />
        <button className="profile__image--edit">
          <input
            className="profile__image--edit"
            type="file"
            name="avatar"
            id="file"
            accept=".jpef, .png, .jpg"
            onChange={photoUpload}
            src={image}
          />
          <Icon name={Icons.pen} color={IconsColor.white} />
        </button>
      </div>

      <div className="profile-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="login-page-form-row">
            <div className="login-page-form-row__item">
              <LoginInput
                label={translate('name')}
                name="firstname"
                value={profileForm.firstname}
                required
                placeholder={translate('name')}
                onChange={setProfileForm}
              />
            </div>
            <div className="login-page-form-row__item">
              <LoginInput
                label={translate('surname')}
                name="lastname"
                value={profileForm.lastname}
                placeholder={translate('surname')}
                required
                onChange={setProfileForm}
              />
            </div>
          </div>
          <LoginInput
            label={translate('position')}
            name="post"
            value={profileForm.post}
            onChange={setProfileForm}
          />

          <LoginInput
            label={translate('company')}
            name="company"
            value={profileForm.company}
            onChange={setProfileForm}
          />

          <LoginInput
            label={translate('phone_number')}
            name="phone"
            value={profileForm.phone}
            placeholder={translate('phone_number')}
            type="tel"
            pattern="[0-9]{9}"
            title={translate('phone_number_err')}
            onChange={setProfileForm}
          />

          <LoginInput
            label={translate('email')}
            name="email"
            value={profileForm.email}
            placeholder={translate('email')}
            required
            type="email"
            onChange={setProfileForm}
          />

          <button className="profile__submit" type="submit">
            {translate('save')}
          </button>
        </form>

        <div className="profile__delete">
          <h2>{translate('delete_account')}</h2>
          <span>{translate('delete_account_text')}</span>
          <AskingModal
            buttonText={translate('delete_account')}
            text={translate('delete_account_modal_text')}
            func={deleteAccount}
          />
        </div>
      </div>
    </>
  )
}

export default ProfileEdit
