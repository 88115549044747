import React from 'react'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../Icon'
import { getTimeFromDate, getDayName, formatDate } from '../../utils/functions'
import { AgendaItemsProps } from '../../redux/dashboard/interfaces'
import searchSubstring from '../../helpers/searchSubstring'
import translate from '../../translate'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../redux/dashboard/selectors'

const SchedulerItem = ({
  active,
  data,
}: {
  active?: boolean
  data: AgendaItemsProps | undefined
}) => {
  return (
    <div className={active ? 'active' : undefined}>
      <div>
        <img src={data?.icon_url} alt={data?.name} />

        <span>
          {data?.name} <br />
          {data?.datetime_start && data.datetime_end && (
            <time>
              {getTimeFromDate(data.datetime_start)} - {getTimeFromDate(data.datetime_end)}
            </time>
          )}
        </span>
      </div>
      {/* <div className="icons-wrapper">
        <Icon
          name={Icons.calendar}
          color={IconsColor.grey}
          hoverColor={IconsColor.xiaomi}
          type={IconsFillType.stroke}
        />
        <Icon
          name={Icons.bell}
          color={IconsColor.grey}
          hoverColor={IconsColor.xiaomi}
          type={IconsFillType.stroke}
        />
      </div> */}
    </div>
  )
}

const Scheduler: React.FC<{
  day: number
  data: AgendaItemsProps[] | undefined
  selected: number | null
  onChange: (id: number) => void
  search?: string
}> = ({ day, data, selected, onChange, search }) => {
  const lang = useSelector(selectLanguage)
  const actualDay =
    data && data.length > 0 ? new Date(data[0].datetime_start).getDay() : new Date().getDay()

  data?.sort((a: AgendaItemsProps, b: AgendaItemsProps) => {
    const aDate = new Date(a.datetime_start).getTime()
    const bDate = new Date(b.datetime_start).getTime()
    return aDate - bDate
  })

  return (
    <section className="scheduler__content">
      <ul className="scheduler__main-list">
        <li className="scheduler__main-list__item">
          <div>
            <span>
              {translate('day')} {day}
            </span>
            <time>
              {getDayName(actualDay, lang)}{' '}
              {data && data.length > 0 && formatDate(data[0].datetime_start)}
            </time>
          </div>
          <ul>
            {data
              ?.filter((element) => searchSubstring(element.name, search))
              .map((el: AgendaItemsProps) => {
                return (
                  <React.Fragment key={el.id}>
                    <li className="time-only">
                      <time>{getTimeFromDate(el.datetime_start)}</time>
                    </li>
                    <li onClick={() => onChange(el.id)}>
                      <SchedulerItem active={el.id === selected} data={el} />
                    </li>
                  </React.Fragment>
                )
              })}
            <li className="time-only">
              <time>
                {data && data.length > 0 && getTimeFromDate(data[data.length - 1].datetime_end)}
              </time>
            </li>
          </ul>
        </li>
      </ul>
    </section>
  )
}

export default Scheduler
