import React, { useState, FC } from 'react'
import Icon from '../../components/Icon'
import { Icons, IconsColor } from '../../utils/enums'
import LoginInput from '../../components/LoginInput'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import { UserAgreements } from '../../redux/user/interfaces'
import { asyncFetchAccountUpdate } from '../../redux/user/async'
import { findIndex } from '../../utils/functions'
import translate from '../../translate'

const ProfileSettings: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)

  const copyAgreements = JSON.parse(JSON.stringify(user?.user_agreements))

  const [agreements, setAgreements] = useState(
    copyAgreements?.map((element: UserAgreements) => {
      if (element.display === undefined) {
        element.display = false
      }
      return element
    }) || [],
  )

  const checkedAgreements = copyAgreements
    .filter((element: UserAgreements) => {
      if (element.is_checked === true) {
        return element
      }

      return null
    })
    .map((element: UserAgreements) => {
      return element.id
    })

  const [profileForm, setProfileForm] = useState({
    networking_on: user?.networking_on || 0,
    user_agreements: checkedAgreements || [],
  })

  const showText = (id: number) => {
    setAgreements((prev: UserAgreements[]) => {
      const state = [...prev]
      const index = findIndex(state, id)
      state[index].display = !state[index].display
      return state
    })
  }

  const check = (id: number) => {
    setAgreements((prev: UserAgreements[]) => {
      const state = [...prev]
      const index = findIndex(state, id)
      state[index].is_checked = !state[index].is_checked
      return state
    })

    setProfileForm(() => {
      const state = { ...profileForm }

      const agreementsToSend = agreements
        .filter((element: UserAgreements) => element.is_checked)
        .map((element: UserAgreements) => element.id)

      state.user_agreements = agreementsToSend

      return state
    })
  }

  const save = () => {
    dispatch(
      asyncFetchAccountUpdate({
        additionalHeaders: { Authorization: `JWT ${user?.token}` },
        body: profileForm,
        token: token || null,
      }),
    )
  }

  return (
    <>
      <h1>{translate('settings_agreements')}</h1>
      <div className="profile-checkbox">
        <h2>{translate('networking')}</h2>
        <LoginInput
          label={translate('show_me_on_networking')}
          name="networking_on"
          type="checkbox"
          checked={profileForm.networking_on}
          onChange={setProfileForm}
        />
      </div>
      <div className="profile-checkbox">
        <h2>{translate('agreements')}</h2>
        {agreements.map((element: any) => {
          return (
            <div key={element.id} className="profile-checkbox--extended">
              <div className="profile-checkbox__inputs">
                <label className="login-page-checkbox__container">
                  {element.title || element.url}
                  {element.is_required && !element.is_checked && (
                    <span style={{ color: 'red' }}>{translate('required')}</span>
                  )}
                  <input
                    name={element.id}
                    type="checkbox"
                    required={element.is_required}
                    checked={element.is_checked}
                    onChange={() => check(element.id)}
                  />
                  <span className="login-page-checkbox__checkmark"></span>
                </label>
                <button
                  className={`profile-checkbox__btn ${
                    element.display ? 'profile-checkbox__btn--active' : ''
                  }`}
                  onClick={() => {
                    showText(element.id)
                  }}
                >
                  <Icon
                    className="profile-aside-item__arrow"
                    name={Icons.arrowRight}
                    color={IconsColor.black}
                    hoverColor={IconsColor.xiaomi}
                  />
                </button>
              </div>
              {element.display && (
                <div className="profile-checkbox__extended">
                  {element.content && (
                    <div dangerouslySetInnerHTML={{ __html: element.content }}></div>
                  )}
                  {element.url && <a href={element.url}>{translate('see_more')}</a>}
                </div>
              )}
            </div>
          )
        })}

        <button className="profile__submit" onClick={save}>
          {translate('save')}
        </button>
      </div>
    </>
  )
}

export default ProfileSettings
