import React, { FC, useState, useEffect } from 'react'
import Modal from 'react-modal'
import { IconsColor, Icons } from '../../utils/enums'
import TabButton from '../TabButton'
import { Rate } from 'antd'
import { useSelector } from 'react-redux'
import { selectSlug } from '../../redux/dashboard/selectors'
import { PollModalData, PollModalDataQuestions } from '../../redux/dashboard/interfaces'
import { EVENT_URL, LANGUAGE, APP_VERSION, API_KEY } from '../../services/consts'
import translate from '../../translate'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

Modal.setAppElement('#root')

interface Props {
  id: number
}

const PollModal: FC<Props> = ({ id }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState<null | PollModalData>(null)
  const slug = useSelector(selectSlug)
  const [rates, setRates] = useState({})

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setModalData(null)
    setIsOpen(false)
  }

  const handleChange = (name: number, value: number | string) => {
    setRates((prev) => {
      const state = { ...prev }

      Object.defineProperty(state, name, {
        value: value,
      })

      return state
    })
  }

  function getData(url = '', APP_VERSION: string | undefined, API_KEY: string) {
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'App-Version': APP_VERSION || '',
        'Api-Key': API_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const questionsIds = data.questions.map((element: PollModalDataQuestions) => [
          element.id,
          0,
        ])
        const objIds = Object.fromEntries(questionsIds)
        setRates(objIds)
        setModalData(data)
      })
  }

  useEffect(() => {
    if (modalIsOpen) {
      getData(
        `${EVENT_URL}/${slug}/${LANGUAGE}/api/polls/model/AgendaItem/${id}/`,
        APP_VERSION,
        API_KEY,
      )
    }
  }, [modalIsOpen])

  return (
    <div>
      <TabButton
        text={translate('poll')}
        iconName={Icons.star}
        iconColor={IconsColor.white}
        iconColorHover={IconsColor.xiaomi}
        onClick={openModal}
      />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <form onSubmit={() => console.log(rates)}>
          <div className="modal">
            <h2>{modalData?.name || translate('poll_unavailable')}</h2>

            {modalData?.questions.map((element) => (
              <div className="modal-rate" key={element.id}>
                {element.type === 'five-point-scale' && (
                  <>
                    <span className="modal-rate__text">{element.text}</span>
                    <Rate onChange={(value: number) => handleChange(element.id, value)} />
                    <hr />
                  </>
                )}

                {element.type === 'ten-point-scale' && (
                  <>
                    <span className="modal-rate__text">{element.text}</span>
                    <Rate
                      count={10}
                      onChange={(value: number) => handleChange(element.id, value)}
                    />
                    <hr />
                  </>
                )}

                {element.type === 'text' && (
                  <>
                    <span className="modal-rate__text">{element.text}</span>
                    <input onChange={(e) => handleChange(element.id, e.target.value)} />
                    <hr />
                  </>
                )}

                {element.type === 'number' && (
                  <>
                    <span className="modal-rate__text">{element.text}</span>
                    <input
                      type="text"
                      pattern="[0-9]{0,10}"
                      title={translate('enter_only_digits')}
                      onChange={(e) => handleChange(element.id, e.target.value)}
                    />
                    <hr />
                  </>
                )}
              </div>
            ))}

            {modalData && (
              <button className="modal__send-btn" type="submit">
                {translate('send')}
              </button>
            )}
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default PollModal
