import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import App from './js/views/App'

import { store, history } from './js/redux/store'
import * as serviceWorker from './js/serviceWorker'

import './scss/app.scss'
import 'antd/dist/antd.css'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import AppStateProvider, { useAppState } from './twilio/state'
import ErrorDialog from './twilio/components/ErrorDialog/ErrorDialog'
import theme from './twilio/theme'
import './twilio/types'
import { ChatProvider } from './twilio/components/ChatProvider'
import { VideoProvider } from './twilio/components/VideoProvider'
import useConnectionOptions from './twilio/utils/useConnectionOptions/useConnectionOptions'
import { IntlProvider } from 'react-intl'
import { messages } from './js/translate'
import { useSelector } from 'react-redux'
import { selectLanguage } from './js/redux/dashboard/selectors'

const persistor = persistStore(store)

const VideoApp = () => {
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions()
  const lang = useSelector(selectLanguage)

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatProvider>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <App />
        </IntlProvider>
      </ChatProvider>
    </VideoProvider>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <HashRouter>
            <AppStateProvider>
              <VideoApp />
            </AppStateProvider>
          </HashRouter>
        </ConnectedRouter>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
