import React, { FC } from 'react'
import Icon from '../Icon'
import { Icons, IconsColor } from '../../utils/enums'

import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import { blueIcon } from '../../utils/leafletIcons'

interface ContactTextProps {
  icon: Icons
  text: string
}

const ContactText: FC<ContactTextProps> = ({ icon, text }) => {
  return (
    <div className="access-text">
      <Icon name={icon} color={IconsColor.xiaomi} />
      <span>{text}</span>
    </div>
  )
}

interface ContactProps {
  name: string
  street?: string
  city: string
  country: string
  buses?: string
  subways?: string
  trams?: string
  access_details?: string
  lat?: number
  lon?: number
}

const Contact: FC<ContactProps> = ({
  name,
  street,
  city,
  country,
  buses,
  subways,
  trams,
  access_details,
  lat,
  lon,
}) => {
  const mapAttribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  const mapURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

  const position = {
    lat: lat,
    lng: lon,
  }

  return (
    <>
      <div>
        <h2>{name}</h2>
        <p>{`${street ? `${street}, ` : ''}${city}, ${country}`}</p>
        {buses && <ContactText icon={Icons.bus} text={buses} />}
        {subways && <ContactText icon={Icons.subway} text={subways} />}
        {trams && <ContactText icon={Icons.tram} text={trams} />}
        {access_details && <p>{access_details}</p>}
      </div>
      {lat && lon && (
        <MapContainer className="access-map" center={[lat, lon]} zoom={16} scrollWheelZoom={true}>
          <TileLayer attribution={mapAttribution} url={mapURL} />
          <Marker icon={blueIcon} position={[lat, lon]}>
            <Tooltip direction="top" permanent={true}>
              {name}
            </Tooltip>
          </Marker>
        </MapContainer>
      )}
    </>
  )
}

export default Contact
