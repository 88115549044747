import React, { useState, useEffect } from 'react'
import Dashboard from '../../views/Dashboard'
import Scheduler from '../../components/Scheduler'
import InfoBar from '../../components/InfoBar'
import MainTitle from '../../components/MainTitle'
import MainSearch from '../../components/MainSearch'
import NavigationHeader from '../../components/NavigationHeader'

import { useSelector } from 'react-redux'
import {
  selectAgendaItems,
  selectEventConfiguration,
  selectWidth,
} from '../../redux/dashboard/selectors'
import { AgendaItemsProps } from '../../redux/dashboard/interfaces'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'
import translate from '../../translate'

const Agenda: React.FC = () => {
  const [selected, setSelected] = useState<number | null>(null)
  const [selectedItem, setSelectedItem] = useState<AgendaItemsProps | undefined>()
  const [search, setSearch] = useState('')

  const agendaItems = useSelector(selectAgendaItems)
  const event = useSelector(selectEventConfiguration)
  const width = useSelector(selectWidth)

  const [days, setDays] = useState([] as string[])

  useEffect(() => {
    if (agendaItems !== undefined) {
      const actualDays = Array.from({ length: agendaItems.length }, (_u, i) =>
        agendaItems[i].datetime_start.slice(0, 10),
      )
      setDays([...new Set(actualDays)].sort())
    }
  }, [agendaItems])

  useEffect(() => {
    setSelectedItem(agendaItems?.find((element: AgendaItemsProps) => element.id === selected))
  }, [selected])

  useEffect(() => {
    setSelected(null)
    setSelectedItem(undefined)
  }, [event])

  return (
    <Dashboard activePage="agenda" width={width}>
      <div className="main__container">
        {((selected === null && selectedItem === undefined) || width >= 1025) && (
          <div className="main__wrapper">
            <NavigationHeader firstItem="Agenda" />
            <div className="main__top">
              <MainTitle title={translate('agenda')} />
              <MainSearch value={search} onChange={setSearch} />
            </div>
            {days.map((element, index) => {
              return (
                <Scheduler
                  key={element}
                  onChange={(id) => setSelected(id)}
                  selected={selected}
                  day={index + 1}
                  search={search}
                  data={agendaItems?.filter(
                    (agendaElement: AgendaItemsProps) =>
                      agendaElement.datetime_start.slice(0, 10) === element,
                  )}
                />
              )
            })}
            {/* <Scheduler onChange={(id) => setSelected(id)} selected={selected} data={agendaItems} /> */}
          </div>
        )}

        {selected !== null && selectedItem !== undefined && <InfoBar data={selectedItem} />}
        {selected !== null && selectedItem !== undefined && (
          <div className="sidebar__mobile-bar-wrapper sidebar__mobile-bar-wrapper-agenda">
            <div className="agenda-bar-item">
              <span>Zamknij</span>
              <button
                onClick={() => {
                  setSelected(null)
                  setSelectedItem(undefined)
                }}
              >
                <Icon
                  name={Icons.downArrow}
                  color={IconsColor.xiaomi}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </Dashboard>
  )
}

export default Agenda
