import React, { useState, FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Login from '../../views/Login'
import LoginInput from '../../components/LoginInput'
import { asyncFetchRegister } from '../../redux/user/async'
import { selectUserLoading, selectUser, selectUserToken } from '../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileFormProps } from '../../redux/user/interfaces'

import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { Button } from 'antd'
import translate from '../../translate'

import { EVENT_URL, LANGUAGE, API_KEY, APP_VERSION } from '../../services/consts'

interface AgreementProps {
  id: number
  content: string
  title: string
  is_required: boolean
  url: string
}

const RegisterPage: FC = () => {
  const history = useHistory()
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const loading = useSelector(selectUserLoading)

  const dispatch = useDispatch()

  const [registerForm, setRegisterForm] = useState({
    password: '',
    repeatPassword: '',
    photo: '',
    firstname: '',
    lastname: '',
    networking_on: false,
    post: '',
    status: '',
    company: '',
    phone: '',
    email: '',
    user_agreements: [],
  })

  const [userAgreements, setUserAgreements] = useState([])

  async function getData(url = '') {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'App-Version': APP_VERSION || '',
        'Api-Key': API_KEY,
      },
    })
    return response.json()
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validate()) {
      const body: ProfileFormProps = registerForm
      dispatch(asyncFetchRegister({ additionalHeaders: {}, body }))
    }

    e.preventDefault()
  }

  const validate = () => {
    if (registerForm.password !== registerForm.repeatPassword) {
      dashboardMessage(DashboardMessageTypes.error, 'Wprowadzone hasła różnią się od siebie!')
      return false
    }

    return true
  }

  useEffect(() => {
    if (user !== null && token !== null) {
      history.goBack()
    }
  }, [user, token])

  useEffect(() => {
    getData(`${EVENT_URL}/${LANGUAGE}/api/agreements/`).then((data) => {
      setUserAgreements(data)
    })
  }, [])

  // useEffect(() => {
  //   console.log(userAgreements)
  // }, [userAgreements])

  // useEffect(() => {
  //   console.log(registerForm)
  // }, [registerForm])

  return (
    <Login>
      <div className="login-page">
        <h1>{translate('register')}</h1>

        <form onSubmit={handleSubmit}>
          <div className="login-page-form-row">
            <div className="login-page-form-row__item">
              <LoginInput
                label={translate('name')}
                name="firstname"
                minLength={2}
                required
                placeholder={translate('name')}
                onChange={setRegisterForm}
              />
            </div>
            <div className="login-page-form-row__item">
              <LoginInput
                label={translate('surname')}
                name="lastname"
                minLength={2}
                required
                placeholder={translate('surname')}
                onChange={setRegisterForm}
              />
            </div>
          </div>

          <LoginInput
            label={translate('position')}
            name="post"
            minLength={1}
            onChange={setRegisterForm}
          />

          <LoginInput
            label={translate('company')}
            name="company"
            minLength={1}
            onChange={setRegisterForm}
          />

          <LoginInput
            label={translate('phone_number')}
            name="phone"
            placeholder={translate('phone_number')}
            type="tel"
            pattern="[0-9]{9}"
            title="Numer telefonu powinien zawierać 9 cyfr."
            required
            onChange={setRegisterForm}
          />

          <LoginInput
            label={translate('email')}
            name="email"
            required
            placeholder={translate('email')}
            type="email"
            onChange={setRegisterForm}
          />

          <hr />

          <LoginInput
            label={translate('password')}
            name="password"
            placeholder={translate('password')}
            required
            minLength={6}
            type="password"
            onChange={setRegisterForm}
          />

          <LoginInput
            label={translate('repeat_password')}
            name="repeatPassword"
            required
            placeholder={translate('repeat_password')}
            minLength={6}
            type="password"
            onChange={setRegisterForm}
          />

          {userAgreements.length > 0 &&
            userAgreements.map((element: AgreementProps) => {
              return (
                <div className="login-page-checkbox__wrapper">
                  <LoginInput
                    type="checkbox"
                    label={element.title}
                    name={String(element.id)}
                    checked={registerForm.user_agreements.find(
                      (agreement) => agreement === String(element.id),
                    )}
                    onChange={setRegisterForm}
                    isUserAgreement
                    required={element.is_required}
                    url={element.url}
                    content={element.content}
                  />
                </div>
              )
            })}

          <div className="login-page-checkbox__wrapper">
            <LoginInput
              label={translate('show_me_on_networking')}
              name="networking_on"
              type="checkbox"
              checked={registerForm.networking_on}
              onChange={setRegisterForm}
            />
          </div>

          <Button type="primary" loading={loading} htmlType="submit">
            {translate('register')}
          </Button>
        </form>

        <div className="login-page__links">
          <span>
            {translate('have_account')}{' '}
            <Link className="login-page__link" to="/logowanie">
              {translate('sign_in')}
            </Link>
          </span>
          <Link className="login-page__link" to="/przypomnij-haslo">
            {translate('not_remember_password')}
          </Link>
        </div>
      </div>
    </Login>
  )
}

export default RegisterPage
