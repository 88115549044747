import React, { FC } from 'react'

import Logo from '../../../images/xiaomi.png'
import Heart from '../../../images/heart-outlined.svg'

interface Props {
  logo?: string
  exhibitor?: string
  children?: JSX.Element
}

const ExhibitorBar: FC<Props> = ({ logo, exhibitor, children }) => {
  return (
    <div className={`exhibitor exhibitor--${exhibitor}`}>
      {logo && (
        <div className="exhibitor__logo">
          <img src={logo} alt="logo" />
        </div>
      )}

      {children || (
        <div className="exhibitor__text">
          <span>{exhibitor}</span>
          {/* <img src={Heart} alt="heart" /> */}
        </div>
      )}
    </div>
  )
}

export default ExhibitorBar
